.main-container {
    /* background-color: red; */
    padding: 0rem 0 0rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
h1 {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
}

.main-header {
    margin-right: 3px;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    width: 40px;
    height: 40px;
}